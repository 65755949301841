import React from "react";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
export default function DashboardAdmin() {
  return (
    <>
      <GlobalStyle />
      <Layout>
        <main>
          <h1>Admin Dashboard</h1>
        </main>
      </Layout>
    </>
  );
}
